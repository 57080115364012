<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item :disabled="true">Ajout d'un utilisateur</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <User
                :user="user.userData"
                :user-record-method="recordUser"
                record-text="Créer l'utilisateur"
                cancel-text="Réinitialiser"
                :show-password-fields="true"
                :username-editable="true"
                :show-roles-selection="true"
            />
        </div>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import User from '@/components/User'
import { mapActions, mapMutations } from 'vuex'
export default {
    name: 'UserCreate',
    components: { User },
    data: () => ({
        user: User.props.user.default(),
    }),
    computed: {},
    methods: {
        ...mapMutations('users', ['UPDATE_USERS']),
        ...mapActions('notifications', ['addNotification']),
        recordUser(userData) {
            return backendApi
                .createUser(this.$store.state.login.user.token, userData)
                .then((data) => {
                    this.user = User.props.user.default()
                    this.UPDATE_USERS(data)
                    this.addNotification({ status: 'success', message: 'Utilisateur créé avec succès' })
                    this.$router.push({ name: 'users' })
                })
                .catch(backendApi.notificationOnError)
        },
    },
}
</script>

<style scoped></style>
